<template>
    <section class="section-container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9 col-xxl-12 p-4">
                <div class="pb-5 pt-1 m-3 titles">
                    <h1 class="font-weight-bold" >Lo sentimos, ocurrió un error.</h1>
                </div>

                <!-- Mensajes de Error -->
                <div class="pb-5 mt-3 pl-0">
                    <div class="red-border">
                        <p class="messageinicio">
                            No hemos podido procesar tu solicitud, por favor <span @click="back" class="font-weight-bold mt-3 text-blue cursor-pointer">
                                inténtalo de nuevo
                            </span>
                            <br />
                        </p>
                        <br />
                        <h5 class="messageinicio red-warning font-weight-bold">
                            <!-- Mensaje personalizado -->
                            {{ error }}
                        </h5>
                        <br />
                        <p class="messageinicio">
                            Si el error persiste, te ponemos a disposición los siguientes medios de contacto.
                        </p>
                        <!-- Datos de contacto -->
                        <div class="mt-5 py-1 messageinicio">
                            <a target="_blank" href="tel:+525555661583" class="text-blue link d-flex align-items-start">
                                <img width="25" height="18" class="mr-3" src="@/assets/img/phone-icon.svg" alt="Ibancar phone">
                                <strong>55 5566 1583</strong>
                            </a>
                            <div class="d-flex align-items-center mt-3">
                                <img width="25" height="18" class="mr-3" src="@/assets/img/clock-icon.svg" alt="Ibancar clock">
                                <p class="mb-0">Lunes a Viernes de <strong>9:00h</strong> a <strong>18:00h</strong></p>
                            </div>

                            <a class="text-blue d-flex align-items-center mt-3" href="mailto:hola@ibancar.mx">
                                <img width="25" height="18" class="mr-3" src="@/assets/img/mail-icon.svg" alt="Ibancar mail">
                                <strong>hola@ibancar.mx</strong>
                            </a>
                            <a target="_blank" href="https://api.whatsapp.com/send?l=es&phone=5215612673878&text=¡Hola!%20Solicito%20información%20sobre%20el%20préstamo%20con%20garantía%20de%20auto.%20Gracias." class="text-blue link d-flex align-items-end my-3">
                                <img width="25" height="25" class="mr-3" src="@/assets/img/whatsapp_full_icon.svg" alt="Ibancar phone">
                                <strong>56 1267 3878</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
    export default {
        name: 'ErrorPage',
        data () {
            return {
                error: null
            }
        },
        mounted: function() {
            const error = localStorage.getItem('error');
            this.error = error;
        },
        methods: {
            back: function() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/styles/variables.scss";
    @import 'bootstrap/scss/_functions.scss';
    @import 'bootstrap/scss/_variables.scss';
    @import 'bootstrap/scss/_mixins.scss';

    .titles{
        font-family: 'Montserrat-Medium';
        font-size: 2.2rem;
    }

    .section-container {
        margin-top: 140px;
        min-height: calc(100vh - 140px - 500px)
    }

    .red-border {
        width: 100%;
        padding-left: 30px;
        border-left: 2px solid $red-3;
    }

    .messageinicio{
        color: #333;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .red-warning {
        font-family: 'Montserrat-Medium';
        color: $red-3;
    }

    .link{
        line-height: 1;
    }

    .text-blue{
        color: $blue;
    }

    .cursor-pointer{
        cursor: pointer;
    }

    @include media-breakpoint-down(lg){
        .section-container{
            margin-top: 65px;
        }

        .titles {
            h1 {
                font-size: 28px;
            }
        }
    }
</style>